// @flow
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import History from '../history/history';
// Import all reducers as one
import allReducers from '../reducers';

// Makes Redux Devtools plugin for Chrome available, if it exists
// eslint-disable-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Connect redux with history
const connectedHistory = routerMiddleware(History);

export default function configureStore(initialState?: {}) {
  return createStore(allReducers, initialState, composeEnhancers(applyMiddleware(thunk, connectedHistory)));
}
