// @flow
import React from 'react';
import Helmet from 'react-helmet';
import Loading from '@nats/webclient-common/lib/components/Loading/Loading';
import { isCurrentURL, isUrlSameDomainAsCurrent } from '../../helpers/urls';
import styles from './OriginRedirect.module.scss';

type Props = {
  referrer: string,
};

function OriginRedirect(props: Props): React$Element<any> {
  if (props.referrer && !isCurrentURL(props.referrer) && isUrlSameDomainAsCurrent(props.referrer)) {
    window.location = props.referrer;
  } else {
    window.location = '/home';
  }

  return (
    <div className={styles.nonAuthContainer}>
      <div className={styles.redirectBox}>
        <Helmet title="Redirecting" />
        <h1>
          Redirecting <Loading isLoading inline />
        </h1>
      </div>
    </div>
  );
}

export default OriginRedirect;
