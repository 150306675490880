import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import styles from './ForgotPassword.module.scss';
import { forgotPassword, forgotPasswordSetDefault, changePasswordFail } from '../../actions/authActions';
import LoadingSection from '../../components/LoadingSection/LoadingSection';
import { FORGOT_PASSWORD_NO_USERNAME } from '../../helpers/Errors';

export class ForgotPassword extends Component {
  static propTypes = {
    auth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    forgotPassword: PropTypes.func.isRequired,
    forgotPasswordSetDefault: PropTypes.func.isRequired,
    changePasswordFail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    auth: {},
  };

  constructor(props) {
    super(props);
    this.state = { username: '' };
  }

  componentDidMount() {
    this.props.forgotPasswordSetDefault();
  }

  componentWillUnmount() {
    this.props.forgotPasswordSetDefault();
  }

  handleForgotPasswordSubmit = event => {
    event.preventDefault();

    if (this.state.username === '') {
      this.props.changePasswordFail(FORGOT_PASSWORD_NO_USERNAME);
    } else {
      this.props.forgotPassword(this.state.username);
    }
  };

  handleUsernameChange = event => {
    this.setState({ username: event.target.value });
  };

  renderForgotPasswordButtonArea = (busy, success) => (
    <LoadingSection busy={busy} loadingText="Requesting forgot password token">
      <button
        id="submitForgotPasswordButton"
        className="btn"
        disabled={success}
        onClick={this.handleForgotPasswordSubmit}
        data-qa-id="forgotPassword"
      >
        Submit
      </button>
    </LoadingSection>
  );

  render() {
    const { auth } = this.props;
    const busy = auth ? auth.busy : false;
    const error = auth ? auth.error : false;
    const success = auth ? auth.forgotPasswordSuccess : false;

    return (
      <div className={styles.nonAuthContainer}>
        <div className={styles.forgotPasswordPage}>
          <div className={styles.innerFormPage}>
            <Helmet title="Forgot Password" />
            <h1>Forgot Password</h1>
            <div>
              <div>
                {error && !busy && (
                  <div
                    className={`${styles.dangerMsg} ${styles.msgBox}`}
                    id="forgot-password-error-message"
                    data-qa-id="forgotPasswordErrorMessage"
                  >
                    <p>{error}</p>
                  </div>
                )}
                {success && !busy && (
                  <div
                    className={`${styles.successMsg} ${styles.msgBox}`}
                    id="forgot-password-success-message"
                    data-qa-id="forgotPasswordSuccessMessage"
                  >
                    <p>
                      If your username/email matches an existing user then you will receive an email with a link to
                      reset your password.
                    </p>
                  </div>
                )}
              </div>
              {!success && (
                <form className={styles.form} onSubmit={this.handleForgotPasswordSubmit}>
                  <div className={styles.inputWrapper}>
                    <div className={styles.inputIconWrapper}>
                      <i className={['fa', 'fa-user', 'fa-2x'].join(' ')} title="Username/email address" />
                    </div>
                    <input
                      id="usernameField"
                      type="text"
                      placeholder="Enter username/email address"
                      className="form-control"
                      disabled={busy}
                      autoFocus
                      value={this.state.username}
                      onChange={this.handleUsernameChange}
                    />
                  </div>
                  <div>{this.renderForgotPasswordButtonArea(busy, success)}</div>
                </form>
              )}
              <div className={styles.login}>
                <Link to="/">Return to login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = {
  forgotPassword,
  forgotPasswordSetDefault,
  changePasswordFail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
