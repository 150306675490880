// @flow

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import authReducer from './authReducer';
import applicationsReducer from './applicationsReducer';

export default combineReducers({
  applications: applicationsReducer,
  router: routerReducer,
  auth: authReducer,
});
