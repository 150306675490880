/* eslint no-console:off */
// @flow

export default function getConfig(key: string): string {
  if (window.globalConfig === undefined) {
    console.error('Global config is not defined.');
    return '';
  }

  if (!(key in window.globalConfig)) {
    console.error(`Environment variable ${key} not defined`);
    return '';
  }

  return window.globalConfig[key];
}
