// @flow

import type { Action } from '../types/ReduxActionTypes.js.flow';
import authTypes from '../actions/authTypes';
import { ERROR_TYPES } from '../helpers/Errors';

const initialState = {
  error: null,
  errorType: null,
  alert: null,
  logoutMessage: '',
  busy: false,
  username: '',
  email: '',
  passwordState: '',
  newPasswordNeeded: false,
  newPasswordSuccess: false,
  originalPassword: '',
  forgotPasswordSuccess: false,
  resetPasswordSuccess: false,
};

export default function authReducer(state: any = initialState, action: Action) {
  switch (action.type) {
    case authTypes.LOGOUT_SUCCESS: {
      const { logoutMessage } = action.payload;
      return logoutMessage ? { logoutMessage } : {};
    }
    case authTypes.GO_HOME:
      return {
        ...state,
        newPasswordSuccess: false,
      };
    case authTypes.LOGIN_START:
      return {
        ...state,
        busy: true,
        error: null,
        errorType: null,
      };
    case authTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        error: null,
        busy: false,
        errorType: null,
      };
    }
    case authTypes.LOGIN_FAIL:
      return {
        ...state,
        busy: false,
        error: action.payload.error.message,
        errorType: ERROR_TYPES.CREDENTIALS,
      };
    case authTypes.LOGIN_FAIL_NETWORK:
      return {
        ...state,
        busy: false,
        error: action.payload.error.message,
        errorType: ERROR_TYPES.NETWORK,
      };
    case authTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        error: null,
        busy: true,
        errorType: null,
      };
    case authTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        busy: false,
        error: null,
        errorType: null,
        newPasswordSuccess: true,
      };
    case authTypes.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        busy: false,
        error: action.payload.error.message,
        errorType: null,
      };
    case authTypes.NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        busy: false,
        newPasswordNeeded: true,
        username: action.payload.username,
        originalPassword: action.payload.originalPassword,
      };
    case authTypes.NEW_PASSWORD_START:
      return {
        ...state,
        error: null,
        busy: true,
        errorType: null,
      };
    case authTypes.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPasswordNeeded: false,
        newPasswordSuccess: true,
      };
    case authTypes.NEW_PASSWORD_FAIL:
      return {
        ...state,
        busy: false,
        error: action.payload.error.message,
        errorType: null,
      };
    case authTypes.FORGOT_PASSWORD_START:
      return {
        ...state,
        error: null,
        busy: true,
        forgotPasswordSuccess: false,
        errorType: null,
      };
    case authTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        busy: false,
        error: null,
        forgotPasswordSuccess: true,
        errorType: null,
      };
    case authTypes.FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        busy: false,
        error: action.payload.error.message,
        forgotPasswordSuccess: false,
        errorType: null,
      };
    case authTypes.FORGOT_PASSWORD_SET_DEFAULT:
      return {
        ...state,
        busy: false,
        error: null,
        forgotPasswordSuccess: false,
        errorType: null,
      };
    case authTypes.RESET_PASSWORD_START:
      return {
        ...state,
        error: null,
        busy: true,
        resetPasswordSuccess: false,
        errorType: null,
      };
    case authTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        busy: false,
        error: null,
        resetPasswordSuccess: true,
        errorType: null,
      };
    case authTypes.RESET_PASSWORD_FAIL:
      return {
        ...state,
        busy: false,
        error: action.payload.error.message,
        resetPasswordSuccess: false,
        errorType: null,
      };
    case authTypes.RESET_PASSWORD_SET_DEFAULT:
      return {
        ...state,
        busy: false,
        error: null,
        resetPasswordSuccess: false,
        errorType: null,
      };
    default:
      return state;
  }
}
