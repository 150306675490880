import getConfig from './api/helpers/getConfig';

require('core-js/stable');
require('regenerator-runtime/runtime');

const config = {
  development: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || 'development'];

const environment = {
  ...config,
  app: {
    title: 'MAPP',
    description: '',
    head: {
      titleTemplate: 'MAPP: %s',
      meta: [{ name: 'description', content: '' }, { charset: 'utf-8' }],
    },
  },
  tokenUrl: getConfig('TOKEN_URL') || '',
  messagesURL: getConfig('MESSAGES_URL') || '',
  visualURL: getConfig('VISUAL_URL') || '',
  adminURL: getConfig('ADMIN_URL') || '',
  laraURL: getConfig('LARA_URL') || '',
  localAvoidsUrl: getConfig('LOCAL_AVOIDS_URL') || '',
  transitRoutesUrl: getConfig('TRANSIT_ROUTES_URL') || '',
  wingboardUrl: getConfig('WINGBOARD_URL') || '',
  idleTimeout: parseInt(getConfig('USER_IDLE_TIMEOUT') || '720', 10) * 60 * 1000,
};

export const { awsIotHost } = environment;
export default environment;
