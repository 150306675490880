import React from 'react';
import PureComponent from 'react-pure-render/component';
import Helmet from 'react-helmet';
import moment from 'moment';
import { ButtonLink, cookie } from '@nats/webclient-common';
import styles from './TermsOfUse.module.scss';

export default class TermsOfUse extends PureComponent {
  onTermsAccept = () => {
    const expiryDate = moment.utc().add(1, 'year');
    cookie.savePersistentCookie(cookie.PERSISTENT_COOKIE.HAS_ACCEPTED_TERMS, true, {}, expiryDate.toDate());
  };

  render() {
    /* eslint-disable max-len */
    return (
      <div className={styles.nonAuthContainer}>
        <div className={styles.termsBox}>
          <Helmet title="Terms Of Use" />
          <h1>Terms Of Use</h1>
          <div className={styles.tosText} style={{ marginBottom: '25px', marginTop: '25px' }}>
            <ol>
              <li>
                These Terms of Use govern and relate to a NATSMAPP user (&quot;<strong>you</strong>&quot;) engaging the
                provision of NATSMAPP.
              </li>
              <li>
                These Terms of Use cover our standard (non-bespoke) NATSMAPP product which includes its key features.
              </li>
              <li>
                <strong>Account Administration:</strong> Upon successful completion of your registration and NATS’
                validation process, login details will be issued to your designated administrator. Your account details,
                including login details and passwords, should be kept confidential to and by your organisation’s
                designated users.
              </li>
              <li>
                <strong>Use of NATSMAPP:</strong> You shall at all times retain responsibility and accountability for:
                <ol type="i">
                  <li>deciding on its use and implementation of NATSMAPP and its contents.</li>
                </ol>
              </li>
              <li>
                <strong>Licence:</strong> NATS shall grant to your organisation a licence to use NATSMAPP for your own
                internal purposes only on a non-exclusive, non-transferable (including non-transferable to your sister,
                subsidiary and group companies), revocable basis provided that NATS shall at all times owns all
                intellectual property rights relating to NATSMAPP. The use of NATSMAPP does not give you ownership of
                any intellectual property rights in NATSMAPP or the content that is accessed by you.
              </li>
              <li>
                <strong>Access to NATSMAPP:</strong> It is your responsibility to ensure that you have the correct
                devices and third party services to use and access NATSMAPP. You shall take all measures necessary to
                ensure that your equipment and connection to NATSMAPP will not corrupt, interfere with or damage NATS’
                systems.
              </li>
              <li>
                <strong>Activities on the Account:</strong> You shall at all times be responsible for all activities
                that happen on or through your account. NATS retains all rights to review account usage and content to
                monitor NATS’ system performance and to audit your account activities.
              </li>
              <li>
                <strong>NATSMAPP suspension:</strong> NATS reserves all rights to end or suspend your use of NATSMAPP or
                the availability of NATSMAPP. NATS will usually give you no less than three months’ prior notice of such
                termination or suspension but this may not always be possible, if for example, there is a security
                concern or a technical issue.
              </li>
              <li>
                <strong>Consequences of NATSMAPP suspension:</strong> In the event NATS reasonably believes that you
                have violated these Terms of Use, NATS may immediately suspend your accounts or remove or refuse to
                display content.
              </li>
              <li>
                <strong>
                  <em>NATSMAPP</em> Data Protection:
                </strong>{' '}
                By submitting information to or via NATS as part of your use of NATSMAPP (including but not limited to
                the registration process and the continuing access to NATSMAPP), you accept full responsibility for the
                submission of accurate, authentic and correct data. Further, you warrant that all individuals concerned
                have consented to NATS’ use of such information and authorises NATS to check the personal details
                submitted to set up your account.
              </li>
              <li>
                <strong>NATS’ Rights:</strong> For the purposes of delivering and improving NATSMAPP and NATS’
                commercial offerings, NATS shall be entitled to use, develop or share knowledge, experience and skills
                of general application gained through performing and delivering NATSMAPP.
              </li>
            </ol>
          </div>
          <p>
            <ButtonLink data-qa-id="acceptTerms" to="/" className={styles.tosButton} onClick={this.onTermsAccept}>
              OK
            </ButtonLink>
          </p>
        </div>
      </div>
    );
    /* eslint-enable max-len */
  }
}
