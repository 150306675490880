import React from 'react';
import styles from './messageBox.module.scss';

const messageBoxText = {
  'Credentials Error':
    'Sorry! The credentials provided have not been recognised, please check your username and password and try again.',
  'Generic Error': 'Something went wrong, please refresh the page and try again.',
  'Network Error': 'Network error, please check your network connection and try again.',
};

const messageBox = ({ ...messageContents }) => (
  <div className={`messageBox ${styles.dangerMsg} ${styles.msgBox}`} id={`login-${messageContents.type}-message`}>
    <p>{messageContents.errorType ? messageBoxText[messageContents.errorType] : messageBoxText['Generic Error']}</p>

    <p>If you still can&apos;t log in, contact your NATS mos administrator.</p>
  </div>
);

export default messageBox;
