import React from 'react';
import capitalize from 'lodash/capitalize';
import { Header, cookie } from '@nats/webclient-common';
import { getEnvironmentFromHostname } from '@nats/webclient-common/lib/helpers/Urls';
import environment from '../../environment';
import { isAuthenticated } from '../../auth/auth_helper';
import styles from './NavigationHeader.module.scss';

import whiteLogo from '../../static/NATS_Online_Flightmark_White_RGB.svg';
import MOD from '../../static/MOD-WHITE-AW.png';

const changePasswordUrl = '/changePassword';
const logout = () => {
  cookie.clearCookies();
  window.location.replace('/');
};

const getNonProdEnv = () => {
  const env = getEnvironmentFromHostname();
  return env === 'prod' ? '' : capitalize(env);
};

function NavigationHeader() {
  const globalVersionElement = document.getElementById('globalVersion');
  const globalVersion = globalVersionElement ? globalVersionElement.textContent : 'unknown version';

  return (
    <div>
      {isAuthenticated() && (
        <Header
          changePasswordUrl={changePasswordUrl}
          dashboardUrl={environment.loginUrl}
          onLogout={logout}
          showLogo={false}
          showLaunchpad={false}
          idleTimeout={environment.idleTimeout}
          idleAction={logout}
        />
      )}
      <div id="navigation-header" className={styles.header}>
        <div style={{ height: '100%' }}>
          <div className={styles.headerTop}>
            <img src={whiteLogo} className={styles.navigationLogo} alt="NATS" title={globalVersion} />
            <span className={styles.mileEAMS}>MAPP</span>
            <img src={MOD} className={styles.modLogo} alt="MOD" />
            {!isAuthenticated() && (
              <div data-qa-id="env-label" className={styles.environmentLabel}>
                {getNonProdEnv()}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationHeader;
