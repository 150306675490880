import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import styles from './NoAppPermission.module.scss';

export default class NoAppPermission extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { source, cause } = queryString.parse(props.location.search);

    this.state = {
      hasClickedHomeButton: false,
      sourceApp: startCase(source) || 'this application',
      cause: cause || 'permissions',
    };
  }

  handleReturnHome = event => {
    event.preventDefault();
    this.setState({
      hasClickedHomeButton: true,
    });
  };

  renderNoAppPermission() {
    const { sourceApp, cause } = this.state;
    return (
      <div className={styles.noAppPermissionPage}>
        <div className={styles.innerFormPage}>
          <Helmet title="Incorrect Permissions" />
          <h1>Incorrect Permissions</h1>
          <div className={styles.info} data-qa-id="permissionText">
            <p>You do not have permission to use {sourceApp}.</p>
            <p>Please contact your administrator to ensure you have the correct {cause} assigned.</p>
          </div>
          <div>
            <form className={styles.form}>
              <div>
                <button
                  id="returnHomeButton"
                  className="btn"
                  onClick={this.handleReturnHome}
                  data-qa-id="returnHomeButton"
                >
                  Return Home
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.hasClickedHomeButton) {
      return <Redirect to="/home" />;
    }

    return <div className={styles.noAppPermissionContainer}>{this.renderNoAppPermission()}</div>;
  }
}
