// @flow
import urlParse from 'url-parse';
import { hostname, href } from './windowUtil';

export const isCurrentURL = (url: string): boolean => url === href();

export const isUrlSameDomainAsCurrent = (url: string): boolean => {
  const parsed = urlParse(url, {});

  if (!parsed.hostname) {
    // relative url so same host
    return true;
  }

  const targetHost = parsed.hostname.toLowerCase();
  const currentHost = hostname().toLowerCase();

  // Loose check it's not localhost or ip4/6 address
  const usingDomainName = /\..*[a-z]+/.test(currentHost);

  if (!usingDomainName) {
    return targetHost === currentHost;
  }

  const targetHostDomains = targetHost.split('.');
  const currentHostDomains = currentHost.split('.');

  // Assumes at least one subdomain and only the first subdomain changes between URLs
  return (
    targetHostDomains.length === currentHostDomains.length &&
    currentHostDomains.every((dom, i) => i === 0 || dom === targetHostDomains[i])
  );
};

export const parseQueryString = (queryString: string): any => {
  const parsed = urlParse(queryString, true);
  return parsed.query || '';
};
