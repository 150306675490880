import React from 'react';

function PasswordCriteria() {
  return (
    <ul>
      <li>At least 14 characters</li>
      <li>At least 1 number</li>
      <li>Must be different from your current password</li>
      <li>It is recommended that you use 3 random words to make up the 14 character length</li>
      <p style={{ whiteSpace: 'nowrap' }}>(www.ncsc.gov.uk/blog-post/the-logic-behind-three-random-words)</p>
    </ul>
  );
}

export default PasswordCriteria;
