// @flow
import { cookie } from '@nats/webclient-common';
import axios from 'axios';
import types from './authTypes';
import history from '../history/history';
import environment from '../environment';

import type { ThunkAction } from '../types/ReduxActionTypes.js.flow';
import { getApplicationsAndStoreInCookie, getUserAndStoreInCookie, saveSessionCreds } from '../auth/auth_helper';
import {
  CHANGE_PASSWORD_ERROR_MESSAGES,
  FORGOT_PASSWORD_ERROR_MESSAGES,
  GENERIC_MSG,
  ERROR_TYPES,
} from '../helpers/Errors';

export type LogoutType = { type: typeof types.LOGOUT_SUCCESS, payload: { logoutMessage: any } };
export type LoginStartType = { type: typeof types.LOGIN_START, payload: { result: any } };
export type LoginSuccessType = { type: typeof types.LOGIN_SUCCESS, payload: { result: any } };
export type LoginFailType = { type: typeof types.LOGIN_FAIL, payload: { error: any } };
export type LoginFailNetworkError = { type: typeof types.LOGIN_FAIL_NETWORK, payload: { error: any } };

export function logout(logoutMessage: any) {
  return {
    type: types.LOGOUT_SUCCESS,
    payload: {
      logoutMessage,
    },
  };
}

export function goHome() {
  return {
    type: types.GO_HOME,
  };
}

export function loginStart() {
  return {
    type: types.LOGIN_START,
  };
}

export function loginSuccess(response: any) {
  return {
    type: types.LOGIN_SUCCESS,
    payload: {
      result: response,
    },
  };
}

export function loginFail(message: any) {
  return {
    type: types.LOGIN_FAIL,
    payload: {
      error: { message },
    },
  };
}

export function networkError(message: any) {
  return {
    type: types.LOGIN_FAIL_NETWORK,
    payload: {
      error: { message },
    },
  };
}

export function changePasswordStart() {
  return {
    type: types.CHANGE_PASSWORD_START,
  };
}

export function changePasswordSuccess() {
  return {
    type: types.CHANGE_PASSWORD_SUCCESS,
  };
}

// TODO: Review actions as some are reused and need to be renamed
export function changePasswordFail(message: string) {
  return {
    type: types.CHANGE_PASSWORD_FAIL,
    payload: {
      error: { message },
    },
  };
}

export function newPasswordStart() {
  return {
    type: types.NEW_PASSWORD_START,
  };
}

export function newPasswordSuccess() {
  return {
    type: types.NEW_PASSWORD_SUCCESS,
  };
}

export function newPasswordFail(message: string) {
  return {
    type: types.NEW_PASSWORD_FAIL,
    payload: {
      error: { message },
    },
  };
}

export function newPasswordRequired(username: string, password: string) {
  return {
    type: types.NEW_PASSWORD_REQUIRED,
    payload: {
      username,
      originalPassword: password,
    },
  };
}

export function forgotPasswordStart() {
  return {
    type: types.FORGOT_PASSWORD_START,
  };
}

export function forgotPasswordSuccess() {
  return {
    type: types.FORGOT_PASSWORD_SUCCESS,
  };
}

export function forgotPasswordFail(message: string) {
  return {
    type: types.FORGOT_PASSWORD_FAIL,
    payload: {
      error: { message },
    },
  };
}

export function resetPasswordStart() {
  return {
    type: types.RESET_PASSWORD_START,
  };
}

export function resetPasswordSuccess() {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
  };
}

export function resetPasswordFail(message: string) {
  return {
    type: types.RESET_PASSWORD_FAIL,
    payload: {
      error: { message },
    },
  };
}

export function login(username: string, password: string): ThunkAction {
  return function loginRequestThunk(dispatch: Function) {
    dispatch(loginStart());
    return axios
      .post(`${environment.tokenUrl}/api/v1/session`, { user_name: username, password })
      .then(async result => {
        if (result.data._embedded.new_password) {
          dispatch(newPasswordRequired(username, password));
        } else {
          saveSessionCreds(result.data._embedded);
          await getUserAndStoreInCookie();
          await getApplicationsAndStoreInCookie();

          history.push('/originredirect');
          dispatch(loginSuccess(result.data._embedded));
        }
      })
      .catch(err => {
        if (err.message === ERROR_TYPES.NETWORK) {
          dispatch(networkError(err.message));
        } else if (err.response.status >= 400 && err.response.status < 500) {
          dispatch(loginFail(err.message));
        } else {
          dispatch(loginFail(ERROR_TYPES.GENERIC));
        }
      });
  };
}

export function setNewPassword(username: string, originalPassword: string, newPassword: string): ThunkAction {
  return function newPasswordRequestThunk(dispatch: Function) {
    dispatch(newPasswordStart());
    const userDetails = { user_name: username, password: originalPassword, new_password: newPassword };

    return axios
      .post(`${environment.tokenUrl}/api/v1/session`, userDetails)
      .then(async result => {
        saveSessionCreds(result.data._embedded);
        await getUserAndStoreInCookie();
        await getApplicationsAndStoreInCookie();
        dispatch(newPasswordSuccess());
      })
      .catch(err => {
        let { message } = err;
        if (err.response.status === 400 && CHANGE_PASSWORD_ERROR_MESSAGES[err.response.data.error]) {
          message = CHANGE_PASSWORD_ERROR_MESSAGES[err.response.data.error];
        }
        dispatch(newPasswordFail(message));
      });
  };
}

export function changePassword(originalPassword: string, newPassword: string): ThunkAction {
  return function changePasswordRequestThunk(dispatch: Function) {
    dispatch(changePasswordStart());

    const userDetails = { password: originalPassword, new_password: newPassword };
    const token = cookie.getCookie(cookie.COOKIE.ID_TOKEN);
    const headers = { headers: { Authorization: token } };

    return axios
      .post(`${environment.tokenUrl}/api/v1/changePassword`, userDetails, headers)
      .then(async () => {
        dispatch(changePasswordSuccess());
      })
      .catch(err => {
        let { message } = err;

        if (err.response.status === 400 && CHANGE_PASSWORD_ERROR_MESSAGES[err.response.data.error]) {
          message = CHANGE_PASSWORD_ERROR_MESSAGES[err.response.data.error];
        }

        dispatch(changePasswordFail(message));
      });
  };
}

export function forgotPassword(username: string): ThunkAction {
  return function forgotPasswordRequestThunk(dispatch: Function) {
    dispatch(forgotPasswordStart());
    return axios
      .post(`${environment.tokenUrl}/api/v1/forgotPassword`, { username })
      .then(async () => {
        dispatch(forgotPasswordSuccess());
      })
      .catch(err => {
        let message;

        if (err.response.status === 400 && FORGOT_PASSWORD_ERROR_MESSAGES[err.response.data.error]) {
          message = FORGOT_PASSWORD_ERROR_MESSAGES[err.response.data.error];
        } else {
          message = GENERIC_MSG;
        }

        dispatch(forgotPasswordFail(message));
      });
  };
}

export function forgotPasswordSetDefault() {
  return {
    type: types.FORGOT_PASSWORD_SET_DEFAULT,
  };
}

export function resetPassword(username: string, code: string, password: string): ThunkAction {
  return function resetPasswordRequestThunk(dispatch: Function) {
    dispatch(resetPasswordStart());
    return axios
      .post(`${environment.tokenUrl}/api/v1/forgotPasswordConfirm`, { username, code, password })
      .then(async () => {
        dispatch(resetPasswordSuccess());
      })
      .catch(err => {
        let message;

        if (err.response.status === 400 && FORGOT_PASSWORD_ERROR_MESSAGES[err.response.data.error]) {
          message = FORGOT_PASSWORD_ERROR_MESSAGES[err.response.data.error];
        } else {
          message = GENERIC_MSG;
        }

        dispatch(resetPasswordFail(message));
      });
  };
}

export function resetPasswordSetDefault() {
  return {
    type: types.RESET_PASSWORD_SET_DEFAULT,
  };
}
