// @flow

import React, { Component } from 'react';
import connect from 'react-redux/lib/connect/connect';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { cookie } from '@nats/webclient-common';

import { parseQueryString } from '../../helpers/urls';
// eslint-disable-next-line import/no-cycle
import {
  ChangePassword,
  Home,
  Login,
  TermsOfUse,
  NavigationHeader,
  NewPassword,
  NewPasswordSuccess,
  OriginRedirect,
  ForgotPassword,
  ResetPassword,
  NoAppPermission,
} from '../index';
import styles from './App.module.scss';
import environment from '../../environment';
import { isAuthenticated, refreshSession, shouldRefreshSession } from '../../auth/auth_helper';

type Props = {
  path: string,
  isAuthenticated: boolean, // only used to refresh the component when an auth event happens
  location: {
    search: string,
  },
  newPasswordNeeded: boolean,
  newPasswordSuccess: boolean,
};

type LocalState = {
  refreshInterval: *, // must be inferred, see https://github.com/facebook/flow/issues/5627
};

class App extends Component<Props, LocalState> {
  referrer: string;

  resetPasswordValues: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      refreshInterval: setInterval(async () => {
        if (shouldRefreshSession()) {
          await refreshSession();
        }
      }, 10000),
    };
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshInterval);
  }

  setValuesFromQueryString = (queryString: string): void => {
    const REFERRAL_KEY = 'ref';
    const USERNAME_KEY = 'username';
    const CODE_KEY = 'code';
    const queryObject = parseQueryString(queryString);

    this.referrer = queryObject[REFERRAL_KEY] ? queryObject[REFERRAL_KEY] : '';
    this.resetPasswordValues = queryObject[USERNAME_KEY] && queryObject[CODE_KEY];
  };

  hasAcceptedTerms = () => !!cookie.getCookie(cookie.PERSISTENT_COOKIE.HAS_ACCEPTED_TERMS);

  render() {
    const { newPasswordNeeded, newPasswordSuccess } = this.props;
    const path = this.props.path.toLowerCase();

    if (this.props.location && this.props.location.search) {
      this.setValuesFromQueryString(this.props.location.search);
    }

    if (path === '/' && !this.hasAcceptedTerms()) {
      return <Redirect to="/terms" />;
    }

    if ((path === '/newpassword' || path === '/changepassword') && newPasswordSuccess) {
      return <Redirect to="/newpasswordsuccess" />;
    }

    if (path === '/' && newPasswordNeeded) {
      return <Redirect to="/newpassword" />;
      // If the user comes directly to this page we won't have their username and original password
      // so we should direct them to the login first
    }

    if (path === '/newpassword' && !newPasswordNeeded) {
      return <Redirect to="/" />;
    }

    if (path === '/newpasswordsuccess' && !newPasswordSuccess) {
      return <Redirect to="/home" />;
    }

    if (path === '/forgotpassword' && isAuthenticated()) {
      return <Redirect to="/home" />;
    }

    if ((path === '/resetpassword' && !this.resetPasswordValues) || (path === '/resetpassword' && isAuthenticated())) {
      return <Redirect to="/home" />;
    }

    function PrivateRoute({ component: Comp, ...rest }) {
      return (
        <Route
          {...rest}
          render={props =>
            isAuthenticated() ? (
              <Comp {...props} {...rest} />
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      );
    }

    return (
      <div className={styles.App}>
        <Helmet {...environment.app.head} />
        <NavigationHeader />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/terms" component={TermsOfUse} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/resetpassword" component={ResetPassword} />
          <PrivateRoute exact path="/originredirect" component={OriginRedirect} referrer={this.referrer} />
          <Route path="/newpassword" component={NewPassword} />
          <PrivateRoute path="/home" component={Home} />
          <PrivateRoute path="/newpasswordsuccess" component={NewPasswordSuccess} />
          <PrivateRoute path="/changepassword" component={ChangePassword} />
          <PrivateRoute path="/incorrectpermissions" component={NoAppPermission} />
          <Route render={() => <h1>Page not found</h1>} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  path: state.router.location.pathname,
  isAuthenticated: isAuthenticated(),
  push: ownProps.history.push,
  newPasswordNeeded: state.auth.newPasswordNeeded,
  newPasswordSuccess: state.auth.newPasswordSuccess,
});

export { App as PureApp };
export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(App)
);
