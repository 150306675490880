import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import styles from './ResetPassword.module.scss';
import { resetPassword, resetPasswordSetDefault, changePasswordFail } from '../../actions/authActions';
import LoadingSection from '../../components/LoadingSection/LoadingSection';
import { parseQueryString } from '../../helpers/urls';
import PasswordCriteria from '../../components/PasswordCriteria/PasswordCriteria';
import validatePassword, { VALID_PASSWORD } from '../../helpers/validatePassword';

export class ResetPassword extends Component {
  static propTypes = {
    auth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    resetPassword: PropTypes.func.isRequired,
    resetPasswordSetDefault: PropTypes.func.isRequired,
    changePasswordFail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    auth: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.stateFromQueryString(),
      password: '',
      confirmPassword: '',
    };
  }

  componentDidMount() {
    this.props.resetPasswordSetDefault();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.code !== this.props.code || prevProps.username !== this.props.username) {
      this.setState(this.stateFromQueryString());
    }
  }

  componentWillUnmount() {
    this.props.resetPasswordSetDefault();
  }

  stateFromQueryString = () => {
    const querystring = parseQueryString(window.location.search);
    return {
      username: querystring.username,
      code: querystring.code,
    };
  };

  handleResetPasswordSubmit = event => {
    event.preventDefault();

    const passwordValidationResult = validatePassword(this.state.password, this.state.confirmPassword);

    if (passwordValidationResult === VALID_PASSWORD) {
      this.props.resetPassword(this.state.username, this.state.code, this.state.password);
    } else {
      this.props.changePasswordFail(passwordValidationResult);
    }
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  handleConfirmPasswordChange = event => {
    this.setState({ confirmPassword: event.target.value });
  };

  handleResetPasswordCancel = event => {
    event.preventDefault();
    window.location = '/';
  };

  renderResetPasswordButtonArea = busy => (
    <LoadingSection busy={busy} loadingText="Resetting password token">
      <button
        id="submitResetPasswordButton"
        className={`${styles.btnReset} btn`}
        disabled={busy}
        onClick={this.handleResetPasswordSubmit}
        data-qa-id="resetPassword"
      >
        Reset Password
      </button>
      <button
        id="cancelResetPasswordButton"
        className="btn"
        disabled={busy}
        onClick={this.handleResetPasswordCancel}
        data-qa-id="cancelResetPassword"
      >
        Cancel
      </button>
    </LoadingSection>
  );

  render() {
    const { auth } = this.props;
    const busy = auth ? auth.busy : false;
    const error = auth ? auth.error : false;
    const success = auth ? auth.resetPasswordSuccess : false;

    return (
      <div className={styles.nonAuthContainer}>
        <div className={styles.resetPasswordPage}>
          <div className={styles.innerFormPage}>
            <Helmet title="Reset Password" />
            <h1>Reset Password</h1>
            {!success && (
              <div className={styles.info}>
                <p>Please ensure your password meets the following criteria:</p>
                <PasswordCriteria />
              </div>
            )}
            <div>
              <div>
                {error && !busy && (
                  <div
                    className={`${styles.dangerMsg} ${styles.msgBox}`}
                    id="reset-password-error-message"
                    data-qa-id="resetPasswordErrorMessage"
                  >
                    <p>{error}</p>
                  </div>
                )}
                {success && !busy && (
                  <div>
                    <div
                      className={`${styles.successMsg} ${styles.msgBox}`}
                      id="reset-password-success-message"
                      data-qa-id="resetPasswordSuccessMessage"
                    >
                      <p>Your password has been successfully reset</p>
                    </div>
                    <div className={styles.login}>
                      <Link to="/">Return to login</Link>
                    </div>
                  </div>
                )}
              </div>
              {!success && (
                <form className={styles.form} onSubmit={this.handleResetPasswordSubmit}>
                  <div className={styles.inputWrapper}>
                    <div className={styles.inputIconWrapper}>
                      <i className="fa fa-lock fa-2x" title="New Password" />
                    </div>
                    <input
                      id="newPasswordField"
                      type="password"
                      placeholder="New Password"
                      className="form-control"
                      disabled={busy}
                      value={this.state.newPassword}
                      onChange={this.handlePasswordChange}
                      data-qa-id="newPasswordField"
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <div className={styles.inputIconWrapper}>
                      <i className="fa fa-lock fa-2x" title="Confirm Password" />
                    </div>
                    <input
                      id="confirmPasswordField"
                      type="password"
                      placeholder="Confirm Password"
                      className="form-control"
                      disabled={busy}
                      value={this.state.confirmPassword}
                      onChange={this.handleConfirmPasswordChange}
                      data-qa-id="confirmPasswordField"
                    />
                  </div>
                  <div>{this.renderResetPasswordButtonArea(busy)}</div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = {
  resetPassword,
  resetPasswordSetDefault,
  changePasswordFail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
