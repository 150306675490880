import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { setNewPassword, newPasswordFail } from '../../actions/authActions';
import LoadingSection from '../../components/LoadingSection/LoadingSection';
import PasswordCriteria from '../../components/PasswordCriteria/PasswordCriteria';

import styles from './NewPassword.module.scss';
import validatePassword, { VALID_PASSWORD } from '../../helpers/validatePassword';

export class NewPassword extends Component {
  static propTypes = {
    auth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    setNewPassword: PropTypes.func.isRequired,
    newPasswordFail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    auth: {},
  };

  constructor(props) {
    super(props);
    this.state = { password: '', confirmPassword: '' };
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.error !== prevProps.auth.error) {
      this.setState({ error: this.props.auth.error });
    }
  }

  handleNewPasswordSubmit = event => {
    event.preventDefault();

    const passwordValidationResult = validatePassword(this.state.password, this.state.confirmPassword);

    if (passwordValidationResult === VALID_PASSWORD) {
      this.setState({ error: '' });
      this.props.setNewPassword(this.props.auth.username, this.props.auth.originalPassword, this.state.password);
    } else {
      this.props.newPasswordFail(passwordValidationResult);
    }
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  handleConfirmPasswordChange = event => {
    this.setState({ confirmPassword: event.target.value });
  };

  renderSetPasswordButtonArea = busy => (
    <LoadingSection busy={busy} loadingText="Setting Password">
      <button
        id="submitNewPasswordButton"
        className="btn"
        disabled={busy}
        onClick={this.handleNewPasswordSubmit}
        data-qa-id="setPassword"
      >
        Set Password
      </button>
    </LoadingSection>
  );

  renderNewPasswordForm(busy, error) {
    return (
      <div className={styles.newPasswordPage}>
        <div className={styles.innerFormPage}>
          <Helmet title="Set Your Password" />
          <h1>Set Your Password </h1>
          <div className={styles.info}>
            <p>Please create the password you will use to access the system.</p>
            <p>Please ensure your password meets the following criteria:</p>
            <PasswordCriteria />
          </div>
          <div>
            <div>
              {error && !busy && (
                <div
                  className={`${styles.dangerMsg} ${styles.msgBox}`}
                  id="new-password-error-message"
                  data-qa-id="newPasswordErrorMessage"
                >
                  <p>{error}</p>
                </div>
              )}
            </div>
            <form className={styles.form} onSubmit={this.handlenewPasswordSubmit}>
              <div className={styles.inputWrapper}>
                <div className={styles.inputIconWrapper}>
                  <i className="fa fa-lock fa-2x" title="Original Password" />
                </div>
                <input
                  id="newPasswordField"
                  type="password"
                  placeholder="New Password"
                  className="form-control"
                  disabled={busy}
                  value={this.state.password}
                  onChange={this.handlePasswordChange}
                  data-qa-id="newPasswordField"
                />
              </div>
              <div className={styles.inputWrapper}>
                <div className={styles.inputIconWrapper}>
                  <i className="fa fa-lock fa-2x" title="Confirm Password" />
                </div>
                <input
                  id="confirmPasswordField"
                  type="password"
                  placeholder="Confirm Password"
                  className="form-control"
                  disabled={busy}
                  value={this.state.confirmPassword}
                  onChange={this.handleConfirmPasswordChange}
                  data-qa-id="confirmPasswordField"
                />
              </div>
              <br />
              <div>{this.renderSetPasswordButtonArea(busy)}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { auth } = this.props;
    const busy = auth ? auth.busy : false;
    const error = this.state.error || false;

    return <div className={styles.newPasswordContainer}>{this.renderNewPasswordForm(busy, error)}</div>;
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = {
  setNewPassword,
  newPasswordFail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPassword);
