// @flow
import {
  CANNOT_REUSE_PASSWORD_MSG,
  INVALID_PASSWORD_MSG,
  PASSWORDS_DO_NOT_MATCH_MSG,
  PASSWORDS_NOT_PRESENT,
} from './Errors';

export const VALID_PASSWORD = 'VALID_PASSWORD';

const validatePassword = (
  newPasswordToValidate: string,
  confirmedNewPassword: string,
  currentPassword: ?string
): string => {
  if (!newPasswordToValidate || !confirmedNewPassword) {
    return PASSWORDS_NOT_PRESENT;
  }

  if (currentPassword === newPasswordToValidate) {
    return CANNOT_REUSE_PASSWORD_MSG;
  }

  if (newPasswordToValidate === confirmedNewPassword) {
    const pattern = /^(?=.*\d+)[0-9A-Za-z^$.[\]{}()?\-"!@#%&/\\,><':;|_~`+=*]{14,}$/;

    return pattern.test(newPasswordToValidate) ? VALID_PASSWORD : INVALID_PASSWORD_MSG;
  }
  return PASSWORDS_DO_NOT_MATCH_MSG;
};

export default validatePassword;
