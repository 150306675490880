// @flow

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import Provider from 'react-redux/lib/components/Provider';
import { withRouter } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import responseInterceptor from '@nats/webclient-common/lib/interceptors/userCache/axiosResponseErrorInterceptor';
import axios from 'axios';

import './index.scss';
import App from './containers/App/App';
import History from './history/history';
import configureStore from './store/store';
import { refreshSessionInterceptor } from './auth/auth_helper';
import reportWebVitals from './reportWebVitals';

// The interceptor needs to be configured at the very top level in order to catch all requests
axios.interceptors.response.use(null, async error => responseInterceptor(error, refreshSessionInterceptor));

const store = configureStore();
const rootElement: ?Element = document.getElementById('root');

if (rootElement == null) {
  /* Handle error */
} else {
  const render = Component => {
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={History}>
          <Component />
        </ConnectedRouter>
      </Provider>,
      rootElement
    );
  };

  render(withRouter(App));
}

// Learn more about reporting Web Vitals: https://bit.ly/CRA-vitals
reportWebVitals(/* console.table */);
