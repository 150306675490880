// @flow

const types = {
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  CHANGE_PASSWORD_START: 'CHANGE_PASSWORD_START',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',
  GO_HOME: 'GO_HOME',
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGIN_FAIL_NETWORK: 'LOGIN_FAIL_NETWORK',
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  NEW_PASSWORD_START: 'NEW_PASSWORD_START',
  NEW_PASSWORD_FAIL: 'NEW_PASSWORD_FAIL',
  NEW_PASSWORD_SUCCESS: 'NEW_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_START: 'FORGOT_PASSWORD_START',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAIL: 'FORGOT_PASSWORD_FAIL',
  FORGOT_PASSWORD_SET_DEFAULT: 'FORGOT_PASSWORD_SET_DEFAULT',
  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',
  RESET_PASSWORD_SET_DEFAULT: 'RESET_PASSWORD_SET_DEFAULT',
};

export default types;
