import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import LoadingSection from '../../components/LoadingSection/LoadingSection';
import { changePassword, changePasswordFail } from '../../actions/authActions';
import PasswordCriteria from '../../components/PasswordCriteria/PasswordCriteria';
import validatePassword, { VALID_PASSWORD } from '../../helpers/validatePassword';

import styles from './ChangePassword.module.scss';

export class ChangePassword extends Component {
  static propTypes = {
    auth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    changePassword: PropTypes.func.isRequired,
    changePasswordFail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    auth: {},
  };

  constructor(props) {
    super(props);
    this.state = { currentPassword: '', newPassword: '', confirmPassword: '' };
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.error !== prevProps.auth.error) {
      this.setState({ error: this.props.auth.error });
    }
  }

  handleChangePasswordSubmit = event => {
    event.preventDefault();

    const passwordValidationResult = validatePassword(
      this.state.newPassword,
      this.state.confirmPassword,
      this.state.currentPassword
    );

    if (passwordValidationResult === VALID_PASSWORD) {
      this.setState({ error: '' });
      this.props.changePassword(this.state.currentPassword, this.state.newPassword);
    } else {
      this.props.changePasswordFail(passwordValidationResult);
    }
  };

  handleCurrentPasswordChange = event => {
    this.setState({ currentPassword: event.target.value });
  };

  handleNewPasswordChange = event => {
    this.setState({ newPassword: event.target.value });
  };

  handleConfirmPasswordChange = event => {
    this.setState({ confirmPassword: event.target.value });
  };

  cancel = event => {
    event.preventDefault();
    if (document.referrer) {
      window.history.back();
    } else {
      window.location.href = '/';
    }
  };

  renderChangePasswordButtonArea = busy => (
    <LoadingSection busy={busy} loadingText="Changing Password">
      <button
        id="submitChangePasswordButton"
        className="btn"
        disabled={busy}
        onClick={this.handleChangePasswordSubmit}
        data-qa-id="changePassword"
      >
        Change Password
      </button>
      <div className={styles.cancelDiv}>
        <a data-qa-id="cancelChangePassword" href="#" onClick={this.cancel}>
          Cancel
        </a>
      </div>
    </LoadingSection>
  );

  renderChangePasswordForm(busy, error) {
    return (
      <div className={styles.changePasswordPage}>
        <div className={styles.innerFormPage}>
          <Helmet title="Change Password" />
          <h1>Change Password </h1>
          <div className={styles.info}>
            <p>Please ensure your new password meets the following criteria:</p>
            <PasswordCriteria />
          </div>
          <div>
            <div>
              {error && !busy && (
                <div
                  className={`${styles.dangerMsg} ${styles.msgBox}`}
                  id="change-password-error-message"
                  data-qa-id="changePasswordErrorMessage"
                >
                  <p>{error}</p>
                </div>
              )}
            </div>
            <form className={styles.form} onSubmit={this.handleChangePasswordSubmit}>
              <div className={styles.inputWrapper}>
                <div className={styles.inputIconWrapper}>
                  <i className="fa fa-lock fa-2x" title="Original Password" />
                </div>
                <input
                  id="currenPasswordField"
                  type="password"
                  placeholder="Current Password"
                  className="form-control"
                  disabled={busy}
                  value={this.state.currentPassword}
                  onChange={this.handleCurrentPasswordChange}
                  data-qa-id="currentPasswordField"
                />
              </div>
              <div className={styles.inputWrapper}>
                <div className={styles.inputIconWrapper}>
                  <i className="fa fa-lock fa-2x" title="New Password" />
                </div>
                <input
                  id="newPasswordField"
                  type="password"
                  placeholder="New Password"
                  className="form-control"
                  disabled={busy}
                  value={this.state.newPassword}
                  onChange={this.handleNewPasswordChange}
                  data-qa-id="newPasswordField"
                />
              </div>
              <div className={styles.inputWrapper}>
                <div className={styles.inputIconWrapper}>
                  <i className="fa fa-lock fa-2x" title="Confirm Password" />
                </div>
                <input
                  id="confirmPasswordField"
                  type="password"
                  placeholder="Confirm Password"
                  className="form-control"
                  disabled={busy}
                  value={this.state.confirmPassword}
                  onChange={this.handleConfirmPasswordChange}
                  data-qa-id="confirmPasswordField"
                />
              </div>
              <br />
              <div>{this.renderChangePasswordButtonArea(busy)}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { auth } = this.props;
    const busy = auth ? auth.busy : false;
    const error = this.state.error || false;

    return <div className={styles.changePasswordContainer}>{this.renderChangePasswordForm(busy, error)}</div>;
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = {
  changePassword,
  changePasswordFail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
