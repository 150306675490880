import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { goHome } from '../../actions/authActions';

import styles from './NewPasswordSuccess.module.scss';

export class NewPasswordSuccess extends Component {
  static propTypes = {
    goHome: PropTypes.func.isRequired,
  };

  handleNewPasswordSuccessContinue = event => {
    event.preventDefault();
    this.props.goHome();
  };

  renderNewPasswordSuccessForm() {
    return (
      <div className={styles.newPasswordSuccessPage}>
        <div className={styles.innerFormPage}>
          <Helmet title="Password Set" />
          <h1>Password Set</h1>
          <div className={styles.info}>
            <p>Your password has been set successfully. Click continue to proceed.</p>
          </div>
          <div>
            <form className={styles.form} onSubmit={this.handleNewPasswordSuccessContinue}>
              <div>
                <button
                  id="submitNewPasswordSuccessButton"
                  className="btn"
                  onClick={this.handleNewPasswordSuccessContinue}
                  data-qa-id="continue"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div className={styles.nonAuthContainer}>{this.renderNewPasswordSuccessForm()}</div>;
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = {
  goHome,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPasswordSuccess);
