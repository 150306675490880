// @flow

import type { ApplicationActionType } from '../actions/applicationsActions';
import type { ApplicationType } from '../types/ApplicationsStateTypes';
import types from '../actions/applicationsTypes';

const initialState: ApplicationType = {
  applications: [],
  error: null,
  isBusy: false,
  isEnabled: false,
};

export default function applicationsReducer(
  state: ApplicationType = initialState,
  action: ApplicationActionType
): ApplicationType {
  switch (action.type) {
    case types.APPLICATIONS_START:
      return {
        ...state,
        isBusy: true,
        isEnabled: false,
        error: null,
      };
    case types.APPLICATIONS_SUCCESS:
      return {
        ...state,
        isBusy: false,
        isEnabled: true,
        applications: action.payload.applications,
        error: null,
      };
    case types.APPLICATIONS_FAIL:
      return {
        ...state,
        isBusy: false,
        isEnabled: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
