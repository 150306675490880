import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Loading from '@nats/webclient-common/lib/components/Loading/Loading';
import MessageBox from '../MessageBox/MessageBox';

import * as authActions from '../../actions/authActions';
import styles from './Login.module.scss';

export class Login extends Component {
  static propTypes = {
    auth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    authActions: PropTypes.any,
  };

  static defaultProps = {
    auth: {},
    authActions: {},
  };

  constructor(props) {
    super(props);
    this.state = { username: '', password: '' };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const auth = { ...nextProps.auth };

    const error = auth && auth.error ? auth.error : '';
    const alert = auth && auth.alert ? auth.alert : '';

    this.setState({ alert, error }); // eslint-disable-line react/no-unused-state
  }

  handleLoginSubmit = event => {
    event.preventDefault();
    this.props.authActions.login(this.state.username, this.state.password);
  };

  handleUsernameChange = event => {
    this.setState({ username: event.target.value });
  };

  handlePasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  renderLoginButtonArea = busy => (
    <button
      id="submitLoginButton"
      className={`btn ${busy ? styles.loginButtonDisabled : ''}`}
      disabled={busy}
      onClick={busy ? () => {} : this.handleLoginSubmit}
    >
      {busy ? (
        <span>
          <Loading
            isLoading
            inline
            externalClassNames={{ loadingContainer: styles.loginIcon, bars: styles.loginBars }}
          />
          Logging In...
        </span>
      ) : (
        <span>
          <i className={`fa fa-sign-in ${styles.loginIcon}`} />
          Log In
        </span>
      )}
    </button>
  );

  renderLoginForm(auth = {}, busy, alert, error) {
    return (
      <div className={styles.loginPage}>
        <div className={styles.innerFormPage}>
          <Helmet title="Login" />
          <h1>Log into NATS Military Aviation Planning Portal</h1>
          {auth && !auth.token && (
            <div>
              <div>
                {alert && (
                  <div className={`${styles.infoMsg} ${styles.msgBox}`} id="login-alert-message">
                    <p>
                      <strong>{alert}</strong>
                    </p>
                  </div>
                )}
                {error && !busy && <MessageBox messageType="error" errorType={auth.errorType} />}
              </div>
              <form className={styles.form} onSubmit={this.handleLoginSubmit}>
                <div className={styles.inputWrapper}>
                  <div className={styles.inputIconWrapper}>
                    <i className={['fa', 'fa-user', 'fa-2x'].join(' ')} title="Username" />
                  </div>
                  <input
                    id="usernameField"
                    type="text"
                    placeholder="Enter username"
                    className="form-control"
                    disabled={busy}
                    autoFocus
                    value={this.state.username}
                    onChange={this.handleUsernameChange}
                  />
                </div>
                <div className={styles.inputWrapper}>
                  <div className={styles.inputIconWrapper}>
                    <i className={['fa', 'fa-lock', 'fa-2x'].join(' ')} title="Password" />
                  </div>
                  <input
                    id="passwordField"
                    type="password"
                    placeholder="Enter password"
                    className="form-control"
                    disabled={busy}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                  />
                </div>
                <br />
                <div className={styles.termsAboveLogin} id="termsText">
                  By logging in you agree to our <Link to="/terms">Terms of Use</Link>
                </div>
                <div>{this.renderLoginButtonArea(busy)}</div>
                <div className={styles.forgotPassword} id="forgotPasswordText" data-qa-id="forgotPasswordText">
                  <Link to="/forgotpassword">Forgot password?</Link>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { auth } = this.props;
    const { busy } = auth || {};
    const { alert, error } = this.state || {};

    return <div className={styles.nonAuthContainer}>{this.renderLoginForm(auth, busy, alert, error)}</div>;
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(authActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
