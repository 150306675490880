// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Redirect } from 'react-router';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import messages from '../../static/home-page/messages.png';
import visual from '../../static/home-page/visual.png';
import admin from '../../static/home-page/admin.png';
import wingboard from '../../static/home-page/wingboard.png';
import localAvoids from '../../static/home-page/localavoids.png';
import transitRoutes from '../../static/home-page/transitroutes.png';
import lara from '../../static/home-page/lara.png';

import { loadApplications } from '../../actions/applicationsActions';

import styles from './Home.module.scss';
import environment from '../../environment';

const ResponsiveReactGridLayout = WidthProvider(ReactGridLayout); // eslint-disable-line new-cap

type Props = {
  applications: Array<string>,
  error: Error,
  loadApplications: Function,
};

type AppType = {
  name: string,
  systemName: string,
  path: string,
  enabled?: boolean,
  image: string,
  type: string,
  'data-qa-id': string,
  'data-grid'?: {
    x: number,
    y: number,
    w: number,
    h: number,
  },
};

type State = {
  applications: Array<AppType>,
};

const NUM_APPS_PER_ROW = 5;

const apps: Array<AppType> = [
  {
    name: 'MilEAMS Messages',
    systemName: 'MESSAGES',
    path: environment.messagesURL,
    image: messages,
    type: 'external',
    'data-qa-id': 'messages',
  },
  {
    name: 'MilEAMS Visual',
    systemName: 'VISUAL',
    path: environment.visualURL,
    image: visual,
    type: 'external',
    'data-qa-id': 'visual',
  },
  {
    name: 'Admin',
    systemName: 'ADMIN',
    path: environment.adminURL,
    image: admin,
    type: 'external',
    'data-qa-id': 'admin',
  },
  {
    name: 'Local Avoids',
    systemName: 'LOCAL_AVOIDS',
    path: environment.localAvoidsUrl,
    image: localAvoids,
    type: 'external',
    'data-qa-id': 'localAvoids',
  },
  {
    name: 'Transit Routes',
    systemName: 'TRANSIT_ROUTES',
    path: environment.transitRoutesUrl,
    image: transitRoutes,
    type: 'external',
    'data-qa-id': 'transitRoutes',
  },
  {
    name: 'Wingboard',
    systemName: 'WINGBOARD',
    path: environment.wingboardUrl,
    image: wingboard,
    type: 'external',
    'data-qa-id': 'wingboard',
  },
  {
    name: 'LARA',
    systemName: 'LARA',
    path: environment.laraURL,
    image: lara,
    type: 'external',
    'data-qa-id': 'lara',
  },
];

export class Home extends Component<Props, State> {
  // React Grid Layout property
  ref: any;

  componentDidMount() {
    this.props.loadApplications();
  }

  renderMenuItem = (app: AppType) => (
    <div
      data-grid={app['data-grid']}
      data-qa-id={app['data-qa-id']}
      className={styles.col}
      key={`${app.name}_${app.path}`}
    >
      <a type={app.type} href={app.enabled ? app.path : '#'} target={`${app.name === 'LARA' ? '_blank' : '_self'}`}>
        <div className={`${styles.menuItem} ${app.enabled ? '' : styles.disabled}`}>
          <img src={app.image} alt={app.name} data-qa-id="applicationTile" />
          <span>{app.name}</span>
        </div>
      </a>
    </div>
  );

  render() {
    if (this.props.error) {
      return <Redirect to="/" />;
    }

    const dashboardProps = {
      isDraggable: false,
      isResizable: false,
      width: 1200,
      cols: 6,
      useCSSTransforms: false,
      compactType: 'horizontal',
      margin: [10, 25],
    };

    const getApps = (): Array<AppType> => {
      if (!this.props.applications) {
        return [];
      }

      const allowedApps = apps.filter(app => this.props.applications.includes(app.systemName));
      return allowedApps.map((app, appIdx) => ({
        ...app,
        enabled: true,
        'data-grid': {
          x: appIdx % NUM_APPS_PER_ROW,
          y: parseInt(appIdx / NUM_APPS_PER_ROW, 10),
          w: 1,
          h: 1,
        },
      }));
    };

    return (
      <div className={styles.homeContainerMileams}>
        <Helmet title="Home" />
        <div
          className={`${styles.contentContainerMilitary}
         ${styles.contentContainer}`}
        >
          <div className={styles.titleContainer}>
            <span className={styles.mappTitle}>Military Aviation Planning Portal</span>
          </div>
          <div className={styles.grid}>
            <ResponsiveReactGridLayout
              ref={c => {
                this.ref = c;
              }}
              className={styles.grid}
              {...dashboardProps}
            >
              {getApps().map(app => this.renderMenuItem(app))}
            </ResponsiveReactGridLayout>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ applications }) => ({
  applications: applications.applications,
  error: applications.error,
});

const mapDispatchToProps = {
  loadApplications,
};

export { Home as PureHome };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
