import axios from 'axios';
import { cookie } from '@nats/webclient-common';
import moment from 'moment';
import environment from '../environment';
// eslint-disable-next-line import/no-cycle
import { GET_APPLICATIONS_URL } from '../actions/applicationsActions';

export const apiOptions = () => ({
  withCredentials: false,
  headers: {
    Authorization: cookie.getCookie(cookie.COOKIE.ID_TOKEN),
  },
});

export function isAuthenticated() {
  return !!cookie.getCookie(cookie.COOKIE.ID_TOKEN);
}

export function saveSessionCreds(session) {
  const cookieOptions = cookie.getBaseCookieOptions();

  cookie.saveCookie(cookie.COOKIE.ACCESS_TOKEN_EXPIRY, session.access_token_expiry, cookieOptions);
  cookie.saveCookie(cookie.COOKIE.ID_TOKEN, session.id_token, cookieOptions);
  cookie.saveCookie(cookie.COOKIE.REFRESH_TOKEN, session.refresh_token, cookieOptions);
  cookie.saveCookie(cookie.COOKIE.IOT_CREDS, session.awsCredentials, cookieOptions);
}

// duplication of code due to tech debt https://jupiter.bjss.com/browse/NATSMOS-2034
export function saveRefreshSessionCreds(session) {
  const cookieOptions = cookie.getBaseCookieOptions();

  cookie.saveCookie(cookie.COOKIE.ACCESS_TOKEN_EXPIRY, session.accessTokenExpiry, cookieOptions);
  cookie.saveCookie(cookie.COOKIE.ID_TOKEN, session.idToken, cookieOptions);
  cookie.saveCookie(cookie.COOKIE.REFRESH_TOKEN, session.refreshToken, cookieOptions);
}

export function saveApplications(applications) {
  const cookieOptions = cookie.getBaseCookieOptions();
  cookie.saveCookie(cookie.COOKIE.APPLICATIONS, applications, cookieOptions);
}

function saveUser(user) {
  const cookieOptions = cookie.getBaseCookieOptions();

  cookie.saveCookie(cookie.COOKIE.COGNITO_SUB, user.sub, cookieOptions);
  cookie.saveCookie(cookie.COOKIE.COGNITO_USERNAME, user.username, cookieOptions);
  cookie.saveCookie(cookie.COOKIE.ORGANISATION, user.organisation, cookieOptions);
}

const REFRESH_SESSION_URL = `${environment.tokenUrl}/api/v1/refresh`;
export function refreshSession() {
  const baseOptions = apiOptions();

  return axios
    .get(REFRESH_SESSION_URL, {
      ...baseOptions,
      headers: {
        ...baseOptions.headers,
        refresh_token: cookie.getCookie(cookie.COOKIE.REFRESH_TOKEN),
      },
    })
    .then(response => {
      saveRefreshSessionCreds(response.data._embedded);
      return response.status;
    })
    .catch(error => error.response.status);
}

export function refreshSessionInterceptor() {
  // could just use refreshSession directly as it's not a thunk here for whatever reason,
  // but this keeps the naming consistent across applications and lets us refactor more
  // easily later if we want
  return refreshSession();
}

const EXPIRY_MARGIN = 10;
export function shouldRefreshSession() {
  if (!isAuthenticated()) {
    return false;
  }

  const expiryTime = moment.unix(cookie.getCookie(cookie.COOKIE.ACCESS_TOKEN_EXPIRY));
  const currentTime = moment();

  return currentTime.add(EXPIRY_MARGIN, 'minutes').isAfter(expiryTime);
}

const GET_USER_URL = `${environment.tokenUrl}/api/v1/user`;
export function getUserAndStoreInCookie() {
  if (!GET_USER_URL) {
    throw new Error('Unable to retrieve identity data; config not provided');
  }

  return axios
    .get(GET_USER_URL, apiOptions())
    .then(response => {
      saveUser(response.data._embedded);
    })
    .catch();
}

export function getApplicationsAndStoreInCookie() {
  if (!GET_APPLICATIONS_URL) {
    throw new Error('Unable to retrieve identity data; config not provided');
  }

  return axios
    .get(GET_APPLICATIONS_URL, apiOptions())
    .then(response => {
      saveApplications(response.data._embedded.applications);
    })
    .catch();
}
