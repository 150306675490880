export const ATTEMPT_LIMIT_ERROR = 'ATTEMPT_LIMIT_ERROR';
export const ATTEMPT_LIMIT_MSG =
  "You've tried to change your password too many times recently. Please try again later.";
export const INVALID_PASSWORD_ERROR = 'InvalidPasswordException';
export const INCORRECT_PASSWORD_ERROR = 'INCORRECT_PASSWORD_ERROR';
export const INVALID_PASSWORD_MSG = 'The new password does not meet the criteria.';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const INCORRECT_CURRENT_PASSWORD_MSG =
  'Your current password is not correct. Please re-enter your current password.';
export const CANNOT_REUSE_PASSWORD_ERROR = 'CANNOT_REUSE_PASSWORD';
export const CANNOT_REUSE_PASSWORD_MSG = 'You cannot reuse your current password. Please enter a new password.';
export const PASSWORDS_DO_NOT_MATCH_MSG = 'The passwords you have entered do not match. Please re-enter your password.';
export const PASSWORDS_NOT_PRESENT = 'Please enter a new password and confirm it.';

// Forgot password messages
export const FORGOT_PASSWORD_NO_USERNAME = 'Please enter a username/email address';
export const LIMIT_EXCEEDED_ERROR = 'LimitExceededException';
export const TOO_MANY_REQUESTS_ERROR = 'TooManyRequestsException';
export const REQUESTS_EXCEEDED_MSG =
  'Too many forgot password requests have been made recently. Please try again later.';
export const INVALID_CODE_ERROR = 'CodeMismatchException';
export const INVALID_CODE_MSG = 'The supplied code is invalid.';
export const EXPIRED_CODE_ERROR = 'ExpiredCodeException';
export const EXPIRED_CODE_MSG = 'The supplied code has expired.';
export const GENERIC_MSG = 'An error has occurred. Please try again later.';

export const CHANGE_PASSWORD_ERROR_MESSAGES = {
  [ATTEMPT_LIMIT_ERROR]: ATTEMPT_LIMIT_MSG,
  [INCORRECT_PASSWORD_ERROR]: INCORRECT_CURRENT_PASSWORD_MSG,
  [VALIDATION_ERROR]: INVALID_PASSWORD_MSG,
  [CANNOT_REUSE_PASSWORD_ERROR]: CANNOT_REUSE_PASSWORD_MSG,
};

export const FORGOT_PASSWORD_ERROR_MESSAGES = {
  [LIMIT_EXCEEDED_ERROR]: REQUESTS_EXCEEDED_MSG,
  [TOO_MANY_REQUESTS_ERROR]: REQUESTS_EXCEEDED_MSG,
  [INVALID_CODE_ERROR]: INVALID_CODE_MSG,
  [EXPIRED_CODE_ERROR]: EXPIRED_CODE_MSG,
  [INVALID_PASSWORD_ERROR]: INVALID_PASSWORD_MSG,
  [CANNOT_REUSE_PASSWORD_ERROR]: CANNOT_REUSE_PASSWORD_MSG,
};

export const ERROR_TYPES = {
  CREDENTIALS: 'Credentials Error',
  GENERIC: 'Generic Error',
  NETWORK: 'Network Error',
};
