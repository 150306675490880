// @flow
import axios from 'axios';
import { cookie } from '@nats/webclient-common';
// eslint-disable-next-line import/no-cycle
import { apiOptions, saveApplications } from '../auth/auth_helper';
import types from './applicationsTypes';
import environment from '../environment';
import type { ThunkAction } from '../types/ReduxActionTypes.js.flow';

export const GET_APPLICATIONS_URL = `${environment.tokenUrl}/api/v1/applications`;

type ApplicationStartType = {
  type: typeof types.APPLICATIONS_START,
};

type ApplicationSuccessType = {
  type: typeof types.APPLICATIONS_SUCCESS,
  payload: {
    applications: Array<string>,
  },
};

type ApplicationFailType = {
  type: typeof types.APPLICATIONS_FAIL,
  payload: {
    error: any,
  },
};

export type ApplicationActionType = ApplicationStartType | ApplicationSuccessType | ApplicationFailType;

export function applicationsStart(): ApplicationStartType {
  return {
    type: types.APPLICATIONS_START,
  };
}

export function applicationsSuccess(applications: Array<string>): ApplicationSuccessType {
  return {
    type: types.APPLICATIONS_SUCCESS,
    payload: {
      applications,
    },
  };
}

export function applicationsFail(message: any): ApplicationFailType {
  return {
    type: types.APPLICATIONS_FAIL,
    payload: {
      error: { message },
    },
  };
}

export type ApplicationsResponseType = {
  data: {
    _embedded: {
      applications: Array<string>,
    },
  },
};

export function loadApplications(): ThunkAction {
  return function action(dispatch: Function) {
    if (!GET_APPLICATIONS_URL) {
      throw new Error('Unable to retrieve organisation data; config not provided');
    }

    dispatch(applicationsStart());

    return axios
      .get(GET_APPLICATIONS_URL, apiOptions())
      .then((response: ApplicationsResponseType) => {
        saveApplications(response.data._embedded.applications);
        dispatch(applicationsSuccess(response.data._embedded.applications));
      })
      .catch(error => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
          cookie.clearCookies();
        }

        dispatch(applicationsFail(error));
      });
  };
}
